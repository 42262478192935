import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueCalendly from "vue-calendly";
import * as VueGoogleMaps from "vue2-google-maps";
import VueScrollTo from "vue-scrollto";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueScrollTo);
Vue.use(VueCalendly);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAAKxNWBfhlGIKLBZQR5ZY7ynP6Xrqs_Ks",
    libraries: "places",
  },
});
Vue.config.productionTip = false;

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = "gr";
  }
  if (!["en", "gr"].includes(language)) {
    language = "gr";
  }
  // set the current language for i18n.
  i18n.locale = language;
  next();
});

Vue.use(
  VueGtag,
  {
    config: { id: "UA-172956879-1" },
  },
  router
);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
