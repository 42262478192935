<template>
  <div
    id="app"
    class="content-wrapper bg-background-primary text-copy-primary flex flex-col min-h-screen"
  >
    <header class="header-border">
      <nav
        class="container flex flex-wrap justify-between items-center py-4 mx-auto"
      >
        <div class="mb-0 lg:mb-6 xl:mb-0">
          <router-link
            :to="`/${$i18n.locale}`"
            class="font-bold text-xl flex items-end"
          >
            <img src="@/assets/logo.svg" alt="MedInDiet logo" class="w-40" />
          </router-link>
        </div>
        <div class="block lg:hidden">
          <button
            @click="toggle"
            class="flex items-center px-3 py-2 border rounded border-gray-500 hover:text-gray-600 hover:border-gray-600"
            aria-label="toggle menu"
          >
            <svg
              class="current-color h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
                fill="gray"
              />
            </svg>
          </button>
        </div>
        <ul
          class="uppercase tracking-wide font-semibold w-full block flex-grow lg:flex lg:flex-initial lg:w-auto items-center mt-8 lg:mt-0"
          :class="isOpen ? 'block' : 'hidden'"
        >
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.home") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/about`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.about") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/#contact`"
              v-scroll-to="'#contact'"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.contact") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/online_nutrition`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.online_nutrition") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/gesy`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.gesy") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/services`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.services") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/packages`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.packages") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/blog`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.blog") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/books`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.books") }}</router-link
            >
          </li>
          <li class="mb-4 lg:mb-0">
            <language-switcher />
          </li>
        </ul>
      </nav>
    </header>
    <div class="flex-grow">
      <router-view />
    </div>
    <footer class="text-white">
      <div
        class="container mx-auto flex flex-col lg:flex-row justify-start py-4"
      >
        <ul class="flex items-center">
          <li class="mr-4">
            <a
              href="https://www.facebook.com/medinidiet"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
              class="text-white hover:text-gray-400"
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 45 45"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.1194 39.3693V24.0018H30.3037L31.0744 17.985H25.1194V14.1525C25.1194 12.4162 25.6031 11.2275 28.095 11.2275H31.2525V5.86308C29.7162 5.69843 28.172 5.61894 26.6269 5.62495C22.0444 5.62495 18.8981 8.42245 18.8981 13.5581V17.9737H13.7475V23.9906H18.9094V39.3693H25.1194Z"
                  fill-rule="nonzero"
                />
              </svg>
            </a>
          </li>

          <li class="mr-6">
            <a
              href="https://www.instagram.com/medindiet/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
              class="text-white hover:text-gray-400"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 45 45"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.625 3.75H30.375C36.375 3.75 41.25 8.625 41.25 14.625V30.375C41.25 33.2592 40.1042 36.0253 38.0648 38.0648C36.0253 40.1043 33.2592 41.25 30.375 41.25H14.625C8.625 41.25 3.75 36.375 3.75 30.375V14.625C3.75 11.7408 4.89576 8.97467 6.93521 6.93521C8.97467 4.89576 11.7408 3.75 14.625 3.75ZM14.25 7.5C12.4598 7.5 10.7429 8.21116 9.47703 9.47703C8.21116 10.7429 7.5 12.4598 7.5 14.25V30.75C7.5 34.4813 10.5187 37.5 14.25 37.5H30.75C32.5402 37.5 34.2571 36.7888 35.523 35.523C36.7888 34.2571 37.5 32.5402 37.5 30.75V14.25C37.5 10.5188 34.4812 7.5 30.75 7.5H14.25ZM32.3438 10.3125C32.9654 10.3125 33.5615 10.5594 34.001 10.999C34.4406 11.4385 34.6875 12.0346 34.6875 12.6563C34.6875 13.2779 34.4406 13.874 34.001 14.3135C33.5615 14.7531 32.9654 15 32.3438 15C31.7221 15 31.126 14.7531 30.6865 14.3135C30.2469 13.874 30 13.2779 30 12.6563C30 12.0346 30.2469 11.4385 30.6865 10.999C31.126 10.5594 31.7221 10.3125 32.3438 10.3125ZM22.5 13.125C24.9864 13.125 27.371 14.1127 29.1291 15.8709C30.8873 17.629 31.875 20.0136 31.875 22.5C31.875 24.9864 30.8873 27.371 29.1291 29.1291C27.371 30.8873 24.9864 31.875 22.5 31.875C20.0136 31.875 17.629 30.8873 15.8709 29.1291C14.1127 27.371 13.125 24.9864 13.125 22.5C13.125 20.0136 14.1127 17.629 15.8709 15.8709C17.629 14.1127 20.0136 13.125 22.5 13.125ZM22.5 16.875C21.0082 16.875 19.5774 17.4676 18.5225 18.5225C17.4676 19.5774 16.875 21.0082 16.875 22.5C16.875 23.9918 17.4676 25.4226 18.5225 26.4775C19.5774 27.5324 21.0082 28.125 22.5 28.125C23.9918 28.125 25.4226 27.5324 26.4775 26.4775C27.5324 25.4226 28.125 23.9918 28.125 22.5C28.125 21.0082 27.5324 19.5774 26.4775 18.5225C25.4226 17.4676 23.9918 16.875 22.5 16.875Z"
                  fill-rule="nonzero"
                />
              </svg>
            </a>
          </li>
          <li class="mr-6">
            <a
              href="https://twitter.com/SArgyridou"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="twitter"
              class="text-white hover:text-gray-400"
            >
              <svg
                width="20"
                height="17"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.807 1.943a8.588 8.588 0 0 1-2.036 2.135 11.918 11.918 0 0 1-.465 3.854 12.085 12.085 0 0 1-1.452 3.177 12.426 12.426 0 0 1-2.318 2.691c-.897.78-1.978 1.402-3.243 1.867-1.265.464-2.618.697-4.06.697-2.27 0-4.348-.618-6.233-1.854.293.034.62.051.98.051 1.885 0 3.565-.588 5.04-1.764a3.915 3.915 0 0 1-2.363-.825 4.038 4.038 0 0 1-1.432-2.039c.276.043.532.064.766.064.36 0 .717-.047 1.068-.14A3.982 3.982 0 0 1 1.73 8.43c-.617-.754-.925-1.63-.925-2.627v-.051c.57.324 1.182.498 1.835.524a4.1 4.1 0 0 1-1.32-1.47 4.078 4.078 0 0 1-.49-1.969c0-.75.185-1.445.553-2.084a11.548 11.548 0 0 0 3.702 3.05 11.163 11.163 0 0 0 4.669 1.271c-.067-.324-.1-.639-.1-.946 0-1.142.395-2.116 1.187-2.92C11.632.402 12.589 0 13.712 0c1.173 0 2.161.435 2.966 1.304a7.878 7.878 0 0 0 2.576-.997 3.997 3.997 0 0 1-1.785 2.275 7.98 7.98 0 0 0 2.338-.639z"
                  fill-rule="nonzero"
                />
              </svg>
            </a>
          </li>
          <li class="mr-6 mb-1">
            <a
              href="https://www.linkedin.com/in/stavroula-argyridou"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="linkedin"
              class="text-white hover:text-gray-400"
            >
              <svg
                width="20"
                height="20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5,3c0,1.1-0.7,2-2,2C1.8,5,1,4.1,1,3.1C1,2,1.8,1,3,1S5,1.9,5,3z M1,19h4V6H1V19z M14.6,6.2c-2.1,0-3.3,1.2-3.8,2h-0.1 l-0.2-1.7H6.9C6.9,7.6,7,8.9,7,10.4V19h4v-7.1c0-0.4,0-0.7,0.1-1c0.3-0.7,0.8-1.6,1.9-1.6c1.4,0,2,1.2,2,2.8V19h4v-7.4 C19,7.9,17.1,6.2,14.6,6.2z"
                  fill-rule="nonzero"
                />
              </svg>
            </a>
          </li>
          <li class>
            <a
              href="mailto:drargyridou@medindiet.com"
              aria-label="email"
              class="text-white hover:text-gray-400"
            >
              <svg
                width="21"
                height="17"
                viewBox="0 0 25 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 0h20A2.5 2.5 0 0 1 25 2.5v15a2.5 2.5 0 0 1-2.5 2.5h-20A2.5 2.5 0 0 1 0 17.5v-15C0 1.125 1.125 0 2.5 0zm20 4.225V2.5h-20v1.725l10 5 10-5zm0 2.8l-9.438 4.713a1.25 1.25 0 0 1-1.124 0L2.5 7.025V17.5h20V7.025z"
                  fill-rule="nonzero"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  name: "app",
  components: {
    LanguageSwitcher,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
};
</script>

<style src="@/assets/css/tailwind.css" />
